.pagination {
	margin: $global-spacing-unit-tiny;
	&-item {
		border-radius: 0;
		background-color: $gray30;
		color: white;
		border: none;
		border-right: 1px solid white;

		&:first-child  {
			border-radius: 3px 0  0 3px;
		}

		&:last-child {
			border-radius: 0 3px 3px 0;
			border-right: none;
		}

		&--active {
			background: $formColor;
			color: $formBgColor
		}

		&[disabled] {
			border: 1px solid $gray30;
		}
	}
}