.measure-entries {
	&__title-wrapper {
		display: flex;
		flex-direction: column;
		margin: 0 0 $global-spacing-unit-huge 0;
		padding: 0 0 $global-spacing-unit-huge 0;
		border-bottom: 1px solid #ececec;
	}
	&__title-group {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%; //ie11 fix
		margin: 0 0 $global-spacing-unit-huge 0;
		&.at-a-glance-data {
			width: 70%;
		}
	}
	&__title-left {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;

		.edit-measure {
			display: block;
			margin: $global-spacing-unit 0 0 $global-spacing-unit-huge;
		}
	}
	&__title {
		flex: 1;
		max-width: 70%;
	}
	&__description {
		max-width: calc(50% - 150px);
	}

	&__measure-info {
		margin: $global-spacing-unit-huge $global-spacing-unit-huge 0 0;
		//flex: 1;
		&-label {
			color: $gray50;
		}
		&-data {
			margin: $global-spacing-unit 0 0 0;
		}
		&:last-child {
			margin: $global-spacing-unit-huge 0 0 0;
		}
		&--agency,
		&--division {
			flex: 0 0 25%;
		}
	}
	&__intent_to_archive {
		text-align: center;
		/* match button above it... :\ */
		margin: 1rem 0 0 2rem;
	}
}
.performance-data {
	&__title {
		font-size: 1.5rem;
	}
	&__blurb {
		color: $gray50;
		margin-left: $global-spacing-unit;
	}
	&__reported-since {
		color: $gray50;
		margin: $global-spacing-unit 0;
	}
}

.edit-measure__archive-popup {
	background-color: $gray05;
	.archive-popup__buttons {
		display: flex;
		justify-content: flex-end;
		padding: 13px;

		.button:first-child {
			margin-right: 7px;
		}
	}
	.archive-popup {
		&__question {
			font-size: 1.15rem;
			margin-bottom: 18px;
		}
		&__question_sub {
			margin-bottom: 13px;
			&:after {
				content: '*';
				color: red;
			}
		}
	}
	textarea {
		border: 1px solid;
		border-color: $gray50 !important;
		width: 100%;
		fill: white;
		background-color: white;
		padding: 8px;
	}
}
