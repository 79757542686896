/*
############     _input-container.scss      ############
*/
.input-container {
    margin: 0 0 $global-spacing-unit 0;
    $labelSelector: "label:not(.form-error)";

    #{$labelSelector} {
        margin: 0 $global-spacing-unit-small 0 0;
        display: inline-block;
        width: $labelWidth;
        vertical-align: middle;
        text-align: right;

        .stack-form & {
            display: block;
            width: $labelWidthFull;
            text-align: left;
        }
    }

    &.text-area {
        clear: both;

        #{$labelSelector} {
            vertical-align: top;
        }
    }

    &.plain-text {
        #{$labelSelector} {
            vertical-align: top;
        }
        .form-plain-text {
            display: inline-block;
        }
    }

    &.required {
        #{$labelSelector}:before {
            content: "*";
            color: $red;
            display: inline-block;
            margin: 0 .5rem 0 0;
        }
    }

    table & {
        margin: 0;
    }
}