/*
############     _objects-index.scss      ############
containers and general design patterns, 2up, 3up, layout grids, etc.
*/


/* MAIN BODY */

#react {
	width: 100%;
}

.container {
	padding: 0;
	display: flex;
  flex-direction: column;
	width: 0 auto;
}
.svg-icon {
    height: 1em;
    width: 1em;
}

.main-image-seal {
  width: 200px;
  margin: 2em auto 0.5em auto;
  display: flex;
  justify-content: center;
}

.main-text-image {
  width: 175px;
  margin: auto;
}

/* MAIN TEXT SECTION */
.main-container__text{
  width: 78%;
  margin: 3em auto 0em;
}
.main-container__supportTextContainer {
  height: 320px;
  overflow: auto;
}

/* HEADER */
.header-container {
  &__image {
    width: 400px;
    height: auto;
    min-height: 80px;
  }
  &__image:hover {
    cursor: pointer;
  }

  @media (max-width: 600px) {
    &__image {
      width: 250px;
      height: auto;
      min-height: 80px;
    }
  }

}

/* FOOTER */
.footer-container {
    background-color: $gray05;
    color: $neutralColorDark;
    padding: $global-spacing-unit-huge;
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: flex-start;
    position: relative;

    &__image {
        width: 150px;
        height: auto;
    }
    &__contacts {
        display: flex;
        gap: 50px;
        justify-content: center;
        margin-bottom: 80px;
    }
    &__links {
        position:absolute;
        bottom: 0;
        width: 100%;
    }
}
.footer-column {
    max-width: 215px;
    display: flex;
    flex-direction: column;
    
    &__title {
        color: $gray80;
        font-weight: bold;
        height: 32px;
        line-height: 1;
        margin-bottom: 5px;
    }
    &__text {
        font-size: $smallFontSize;
        margin-bottom: 0;
        line-height: 1.7;
    }
    &__links {
        font-size: $smallFontSize;
        margin-bottom: 0;
        line-height: 1.7;
    }
}

.footer-container__contacts .footer-column:last-child {
    max-width: 250px;
}

.footer-container__links .footer-column {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    padding-bottom: 30px;
    max-width: 100%;
}

/* FOOTER - MOBILE */
@media screen and (max-width: 1175px){
    .footer-container__contacts {
        display: flex;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 800) {
    .footer-container__links .footer-column {
        display: flex;
        flex-direction: column;
    }
    .footer-container__links .footer-text {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}
