/*
############     _elements-index.scss      ############
elemental html: h1, h2, h3, ul, li, etc.
*/

html {
    font-family: $normalFont;
    font-weight: $normalFontBaseWeight;
    font-size: $baseFontSize;
    color: $baseColor;
}

h1 {
    padding: 20px 0;
    margin: 0 0 $global-spacing-unit-small 0;
    font-size: $baseFontSize * 2;
    font-weight: $normalFontBaseWeight * 1.4;
}
h2,
h3 {
    margin: 0 0 $global-spacing-unit 0;
}

p, ul, ol {
    line-height: $global-line-height;
    margin: 0 0 $global-spacing-unit 0;
}

iframe {
    width: 80%;
    margin: auto;
}

a {
    color: $baseLinkColor;
    text-decoration: none;

    &:visited {
        color: $neutralColor;
    }
    &:hover,
    &:active {
        // color: $neutralColorDark; 
        text-decoration: underline;
    }
    &:focus {
        outline: .5px solid $neutralColor;
    }
}

hr {
    border: 0;
    border-top: 1px solid $gray20;
    margin: 0 0 $global-spacing-unit 0;
}

// .svg-icon {
//     height: 1em;
//     width: 1em;
// }
