
.all_rules {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .input-container {
        width: 1000px;
    }
}
