/*
############     _header.scss      ############
*/

.measures {
	&__title {
		font-size: 2rem;
		line-height: 2.5rem;
	}
	&__status {
		font-size: .75rem;
	}
	&__nav-link {
		font-size: 0.95rem;
		font-weight: 600;
	}
}