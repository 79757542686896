.character-counter {
    display: inline-block;
    padding: $global-spacing-unit-small;
    font-size: $vsmallFontSize;

    &--limit {
        color: $error;
    }
}

.text-area > .size-full + .character-counter {
    float: right;
    top: -4px;
    position: relative;
}
