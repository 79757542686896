table {
	&.performance-measures,
	&.performance-measure-data {
		th {
			background: initial;
			padding: 16px;
		}
		tr {
			td {
				border-top: 1px solid #E3E3E3;
				border-bottom: 1px solid #E3E3E3;
				padding: 16px;
				vertical-align: top;
				min-width: 115px;
				overflow: visible;

				&.column-status {
					width: 115px;
				}
				&.column-action {
					width: 175px;
				}
				a {
					color: $primaryColor;
				}
			}

			&.pm-status--pending-approval td.column-status {
				color: #7e7e7e;
			}

			&.pm-status--active td.column-status {

			}
			&.pm-status--archived td.column-status {
				color: #c3c3c3;
				text-decoration: line-through;
			}
			&.pm-status--gopb-approved td.column-status {
				color: #028080;
			}
			&.pm-status--lfa-approved td.column-status {
				color: #720072;
			}

			&:nth-child(even) {
				background-color: white;
				td {
					background: transparent;
					border-top: 1px solid #E3E3E3;
					border-bottom: 1px solid #E3E3E3;
				}
			}
			&.mouse-over, &.selected {
				background: transparent;
				color: initial;
				a {
					color: #007eff;
				}
			}
			&:hover {
				box-shadow: none;
				z-index: initial;
				position: initial;
				td {
					border-top: 1px solid #E3E3E3 !important;
					border-bottom: 1px solid #E3E3E3 !important;
				}
			}
		}
		&.filters {
			thead {
				svg.filter-icon {
					fill: #414141;
				}
				input[type="text"]::placeholder, table.filters thead div.date-range-filter-placeholder::placeholder {
					color: #414141;
				}
			}
			tr.filters .react-select-wrapper .react-select__placeholder {
				color: #414141;
			}
		}
	}
	&.performance-measures {
		th {
			&:first-child {
				width: 12%;
			}
			&:nth-child(2) {
				width: 14%;
			}
			&:nth-child(3) {
				width: 50%;
			}
		}
	}
}